.App {
  text-align: center;
}

/* new stuff written by Rainer */

/* image map class */
area:hover {
  cursor: pointer;
}

/* -------- Customer page ---------- */

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* classes for submit request button - customer */
.submit-request-btn {
  z-index: 120;
  height: 60px;
}
/* for placing the button on top of the screen when scrolled past it */
.submit-btn-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 965px;
  height: 100vh;
  max-height: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
  overflow: hidden;
}

/* for toggle btns on window selector */
.toggle-tinted {
  margin-right: 10px;
}

.toggle-van {
  margin-right: 10px;
  width: 133px;
  display: inline-block;
}

/* Tab styling in customer page */
.tab {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
  margin-top: 10px;
}

.tab-button,
.tab-button-active {
  background: none;
  border: none;
  border-bottom: 1px solid #bdbdbd;
  width: 200px;
  font-size: 16px;
  color: #1f004f;
  padding: 6px;
  transition: 0.1s;
}

.tab-button-active {
  border-bottom: 3px solid #b085f5;
  color: #b085f5;
}

.tab-button:disabled {
  color: #bdbdbd;
}

.tab-content {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* error message on submit request on customer page */
.submit-request-msg {
  font-size: large;
  color: rgb(255, 50, 50);
  margin-bottom: 15px;
}

.form-not-filled {
  border: 1.5px solid red;
}

/* -------- Home page ---------- */
.hp-quote-bg-img {
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.hp-reg-form-container {
  flex: 0 0 auto;
  width: 50%;
}

.license-input-container {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  display: flex;
  justify-content: center;
}

/* ----------- */

.empty-test {
  font-family: 'UKNumberPlate';
  visibility: hidden;
  height: 1px;
}

.transparent-element {
  display: none;
}

.pay-now {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #0a2540;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  line-height: 1.5;
  cursor: pointer;
  font-weight: 700;
  background: #0a2540;
  align-items: center;
  position: relative;
}

.pay-now .pay-text {
  margin: auto;
}

.pay-now .pay-icon {
  position: absolute;
  right: 10px;
}

.pay-now.invalid .pay-text {
  color: #ffffff99;
}

.pay-now:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.text-left {
  text-align: left;
}

.terms {
  font-size: 14px;
}

.terms a {
  color: #484848;
}

.pay-success {
  fill: #2ab784;
  border: 2px solid #2ab784;
  border-radius: 50%;
  padding: 4px;
  width: 24px;
  height: 24px;
}

.success-msg {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 16px;
  padding-top: 24px;
}
