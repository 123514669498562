body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'UKNumberPlate';
  src: local('UKNumberPlate'), url(./assets/fonts/UKNumberPlate.ttf), format('truetype');
}

@font-face {
  font-family: 'CourierPrime-Regular';
  src: local('CourierPrime-Regular'), url(./assets/fonts/CourierPrime-Regular.ttf), format('truetype');
}

/* Button Styles */
.btn-raised {
  display: inline-block;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 11px 20px;
  text-decoration: none;
  background: #9557e8;
  color: #fff;
  transition: all 300ms;
}

.btn-raised:hover {
  box-shadow: 0 0 7px 1px #9557e8;
  color: white;
}

.btn-stroked {
  display: inline-block;
  border: 2px solid #9557e8;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 9px 20px;
  text-decoration: none;
  background: none;
  color: #9557e8;
  transition: all 300ms;
}

.btn-stroked:hover {
  background-color: #9557e8;
  color: white;
}

.btn-raised.round,
.btn-stroked.round {
  border-radius: 40px;
  min-width: 140px;
}

.btn-icon {
  min-width: 36px;
  width: 36px;
  height: 36px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: white;
  color: #9557e8;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.text-btn {
  background: none;
  border: none;
  color: #9557e8;
  font-size: 1rem;
  font-weight: 400;
  padding: 0;
  line-height: 1;
}

.btn-icon:disabled,
.btn-raised:disabled,
.btn-stroked:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.quote-card {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.8);
  border-radius: 15px;
  width: 100%;
  max-width: 550px;
  overflow: hidden;
  position: relative;
}
