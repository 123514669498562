.location-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.LS-header {
  text-align: center;
}

.option-header {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 17px;
}

.option-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-left: 14px;
}

.shop-address {
  font-size: 15px;
}

.address-form {
  border: 1px solid #9a73dd;
  padding: 10px;
  border-radius: 10px;
  width: 80%;
  font-size: 14px;
  overflow: visible;
}

.address-input {
  width: 100%;
  border: 1px solid #9a73dd;
  padding: 10px;
  border-radius: 10px;
  color: #9a73dd;
  font-size: 15px;
  height: 45px;
}
