.PA-thank-you-header {
  margin: 50px 0 30px 0;
  color: #9a73dd;
}

.PA-extra-info {
  margin-bottom: 60px;
}

.PA-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
