.background-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(37, 37, 37, 0.837);
  z-index: 99;
}

.pdf-viewer {
  position: fixed;
  max-width: 100%;
  max-height: calc(100% - 32px);
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.pdf-invoice {
  position: relative;
  max-height: 100%;
}

.react-pdf__Page {
  width: fit-content;
  height: calc(100% - 70px);
  margin: auto;
}

.react-pdf__Page__canvas {
  width: unset !important;
  height: unset !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.PDF-button-container {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
}

.PDF-close {
  width: 35px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.PDF-close:hover {
  cursor: pointer;
}

.react-pdf__Page__textContent,
.textLayer {
  display: none;
}

.react-pdf__Page__annotations,
.annotationLayer {
  display: none;
}
