.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
}

.testmon-box {
  height: calc(100% - 16px);
}

.slider-content {
  max-width: 480px;
  margin: auto;
}

.review-author-img {
  width: 60px;
}

.purple-link {
  text-decoration: none;
  color: #9557e8;
}

.purple-link:hover {
  text-decoration: underline;
  color: #9557e8;
}
