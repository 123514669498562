.inlineimage {
  max-width: 470px;
  margin-right: 8px;
  margin-left: 10px;
}
.images {
  display: inline-block;
  height: auto;
  text-align: center;
  width: 60px;
  border-radius: 4px;
}
.form-control {
  height: 48px;
}
.slick-slide-img {
  height: 60px;
  width: 100%;
  border-radius: 6px;
}

.payment-form .form-row {
  margin-bottom: 10px;
}

.payment-form label {
  display: block;
  font-size: 0.93rem;
  text-align: left;
  line-height: 1.86rem;
  color: #30313d;
}

.payment-form input {
  display: block;
  width: 100%;
  padding: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-size: 16px;
  line-height: 1.5;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
  height: 44.39px;
}

.payment-form button[type='submit'] {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #0a2540;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  line-height: 1.5;
  cursor: pointer;
  font-weight: 700;
}

.payment-form button[type='submit']:hover {
  background-color: #00548c;
}

.Input,
.p-FauxInput {
  padding: var(--p-spacing3);
  background-color: var(--colorBackground);
  border-radius: var(--borderRadius);
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}
.p-CardBrandIcons,
.p-CardIcons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.p-CardIcons {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  background: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  outline: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

.success-msg {
  color: rgb(5, 112, 222);
  background: rgba(5, 112, 222, 0.2);
  border-left: 3px solid;
  border-radius: 0 8px 8px 0;
}

.p-CardCvcIcons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  bottom: 0;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
}

.p-CardCvcIcons-svg {
  display: block;
  width: 1.4em;
  height: auto;
}

.pay-now {
  background: #0a2540;
}

.text-left {
  text-align: left;
}
