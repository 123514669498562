.broken-image-wrap {
  position: relative;
}

.broken-image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.broken-image-wrap .title {
  position: absolute;
  top: 8px;
  left: 8px;
  right: auto;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 15px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}

.broken-image-wrap .btn-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.square {
  position: relative;
  width: 100%;
}
.square:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.square > div {
  position: absolute;
  width: 100%;
  height: 100%;
}
