#navbar_top {
  min-height: 64px;
}

.logo-img {
  width: 200px;
}

.header-get-quote-btn {
  white-space: nowrap;
  border: 3px solid #9941e5 !important;
  border-radius: 10px;
  background: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 16px 24px;
  line-height: 1;
}

.header-get-quote-btn:hover {
  cursor: pointer;
  background-color: #9941e5 !important;
  color: #fff !important;
  transition: 0.3s all;
}

.header-or-call {
  white-space: nowrap;
  font-weight: bold;
  font-size: 18px;
  color: #000000;
}

.header-phone-number {
  white-space: nowrap;
  text-decoration: none;
  color: #9941e5 !important;
  font-size: 20px;
  font-weight: bold;
}

@media (min-width: 768px) {
  #navbar_top {
    min-height: 111px;
  }
}

@media (max-width: 767px) {
  .header-get-quote-btn {
    padding: 4px 8px;
  }

  .header-or-call {
    font-size: 16px;
  }

  .header-phone-number {
    font-size: 18px;
  }
}
