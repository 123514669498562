* {
  padding: 0;
  margin: 0;
}
html,
body {
  overflow-x: hidden;
}
@media only screen and (min-width: 300px) and (max-width: 600px) and (max-height: 550px) {
  #workdiv {
    margin-top: 40% !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 820px) and (max-height: 550px) {
  #workdiv {
    margin-top: 30% !important;
  }
}
.bg {
  position: absolute;
  text-align: center;
  top: -15%;
  left: 35%;
  margin-bottom: 7%;
}
.bg img {
  width: 100%;
}
.container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.mainslider {
  background-image: url(src/assets/images/united-kingdom.png);
  background-position: right 50% bottom -15px;
  background-repeat: no-repeat;
  display: flex;
  background-size: contain;
  margin-bottom: 5%;
  flex-direction: column;
}

.leftheader {
  width: 40%;
  float: left;
}
.leftheader img {
  width: 30%;
  margin-top: -20px;
}
.rightheader {
  width: 60%;
  float: left;
  margin-top: 30px;
  text-align: right;
}
.rightheader .btn11 {
  padding: 0px;
  background: #fff;
  width: 175px;
  border-radius: 10px;
  color: #000;
  height: 50px;
  font-weight: bold;
  font-size: 15px;
  border: 3px solid #9941e5 !important;
  margin-bottom: 8px;
  height: 55px;
  margin-right: 20px;
  letter-spacing: 2px;
}
.btn11:hover {
  cursor: pointer;
  background-color: #9941e5;
  color: #fff;
  transition: 0.3s all;
}
.calltext {
  font-weight: bold;
  font-size: 18px;
  color: #000000;

  text-align: center;
  padding-top: 13px;
  margin-right: 20px;
}
.phonetext {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #9941e5;
  opacity: 1;
  padding-bottom: 2px;

  text-align: center;
  padding-top: 10px;
  margin-right: 20px;
}

.newmobilerightheader {
  display: none;
}
.newmobilerightheader1 {
  display: none;
}
.newipad {
  display: none;
}

.carousal {
  width: 80%;
  margin: 0% auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 11%;
}

.topDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
}
.topDiv img {
  width: 50px;
  height: 40px;
}
.maincontent {
  color: #101010;
  font-weight: 700;
  font-size: 50px;
  line-height: 1.3;
  margin: 5% auto;
}

.fixed {
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.btnDiv {
  width: 100%;
  text-align: right;
  z-index: 22200000;
}
.btnDiv button {
  padding-top: 12px;
  padding-bottom: 12px;
  background: #9941e5;
  width: 180px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border: none;
  border-radius: 10px;
}

.works {
  margin: 5% auto;
  width: 75% !important;
  margin: -4% auto 2% !important;
}
.leftworks {
  width: 26%;
  float: left;
}
.leftTop {
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000;
}
.leftBottom {
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
  color: #575757;
}
.rightworks {
  width: 71%;
  float: right;
}
.rightTop {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #9941e5;
}
.rightCenter {
  width: 100%;
}
.head1 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  float: left;
  box-shadow: 0px 1px 10px #00000029;
  background-color: #fff;
}
.head1 div:nth-child(1) {
  padding-top: 10px;
  color: #9941e5;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
}
.head1 div:nth-child(2) {
  color: #000;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 16px;
  font-size: 15px;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
}
.blueline {
  width: 5%;
  float: left;
  border: 3px dashed #7cd6e4;
}
.textworks {
  margin-top: 20px;
}
.textworks div {
  color: #929292;
  font-size: 14px;
}

.clear {
  clear: both;
}

.wrapper3 {
  width: 40%;
  margin: 4% auto 4%;
}
.top {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
}
.bottom {
  width: 100%;
  line-height: 1.5;
}
.left {
  width: 48%;
  float: left;
  height: 150px;
  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px #00000029;
}
.left div:nth-child(1) {
  color: #9941e5;
  font-weight: bold;
  font-size: 16px;
}
.left div:nth-child(2) {
  color: #000;
  font-weight: normal;
  font-size: 14px;
}
.right {
  width: 48%;
  float: right;
  height: 150px;
  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px #00000029;
}

.right div:nth-child(1) {
  color: #9941e5;
  font-weight: bold;
  font-size: 16px;
}
.right div:nth-child(2) {
  color: #000;
  font-weight: normal;
  font-size: 14px;
}

.wrapper {
  width: 75%;
  margin: 0% auto;
}
.approachcontent {
  width: 100%;
  margin: 5% auto 5%;
  display: flex;
  align-items: flex-start;
}
.approachcontent-left {
  width: 40%;
  float: right;
  text-align: right;
}
.approachcontent-left img {
  width: 100%;
  height: auto;
}
.content-right {
  width: 60%;
  float: left;
}
.content-right .content-text {
  width: 85%;
  font-size: 16px;
  color: #707070;
}
.content-right p {
  font-size: 18px;
}
.content-right .content-text .content-heading {
  color: #000 !important;
  font-size: 24px;
  font-weight: bold;
}

.approachcontent1 {
  width: 100%;
  margin: 5% auto 5%;
  display: flex;
  align-items: center;
}
.wrapper1 .approachcontent-left {
  width: 50%;
  float: left;
  text-align: left;
}
.wrapper1 .content-right1 {
  width: 50%;
  float: left;
  padding-left: 20px;
}
.wrapper1 .content-right1 .content-text {
  width: 70%;
  font-size: 16px;
  color: #707070;
}
.wrapper1 .content-right1 p {
  font-size: 22px;
  line-height: 1.5;
}
.wrapper1 .content-right1 .content-text .content-heading {
  color: #000 !important;
  font-size: 24px;
  font-weight: bold;
}
.wrapper1 .approachcontent-left img {
  width: 90%;
  height: auto;
}

.wrapper1 {
  width: 70%;
  margin: 5% auto 2%;
}
.afternotice {
  margin: 0% auto 2% !important;
}
.notice {
  background: #9f4de6;
  border-radius: 10px;
  color: #fff;
  padding: 25px;
}
.noticehead {
  width: 80%;
  margin: 0% auto;
  font-size: 16px;
  font-weight: 600;
}
.noticecontent {
  width: 80%;
  margin: 0% auto;
  text-align: left;
  font-size: 14px;
}

.wrapper2 {
  width: 50%;
  margin: 0% auto 10%;
  margin-top: 2% !important;
}
.afterpaynow {
  margin: 0% auto 4% !important;
}
.new {
  margin: 0% auto 10%;
  width: 60%;
}

.newipad {
  display: block;
}
.quote-footer {
  padding-right: 0;
  padding-left: 0;
  white-space: nowrap;
}
.btn1 {
  white-space: nowrap;
  border: 3px solid #9941e5 !important;
  border-radius: 10px;
  width: 95%;
  height: 60px;
  background: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 7px;
  position: relative;
  left: 16%;
}
.btn1:hover {
  cursor: pointer;
  background-color: #9941e5 !important;
  color: #fff !important;
  transition: 0.3s all;
}
.call {
  white-space: nowrap;
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  text-align: center;
  padding-top: 13px;
}

.num {
  white-space: nowrap;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #9941e5;
  opacity: 1;
  padding-bottom: 2px;
  text-align: left;
  padding-top: 15px;
  position: relative;
  left: -4%;
}
.payment {
  position: relative;
  left: 16%;
}

.copyright {
  position: relative;
  top: 0px;
  height: 100px;
  background-color: #eeeeee;
  text-align: left;
  font: normal normal normal 16px/21px Segoe UI;
  letter-spacing: 0px;
  color: #545454;
  opacity: 1;
}
.copyright > p {
  position: relative;
  top: 39px;
  text-align: center;
}
@media (max-width: 1800px) {
  .newipad {
    display: none;
  }
}

@media (max-width: 1500px) {
  .head1 {
    width: 130px;
    height: 130px;
  }
  .head1 div:nth-child(2) {
    font-size: 14px;
    padding-top: 10px;
  }
  .blueline {
    width: 6%;
  }
  .new {
    margin: 0% auto 6%;
    width: 60%;
  }
  .newipad {
    display: none;
  }
}
@media (max-width: 1330px) {
  .leftworks {
    width: 26%;
  }
  .rightworks {
    width: 70%;
  }
}
@media (max-width: 1300px) {
  .head1 {
    width: 125px;
    height: 125px;
  }
  .head1 div:nth-child(2) {
    font-size: 13px;
    padding-top: 10px;
  }
}

@media (max-width: 1320px) {
  .left {
    height: 160px;
  }
  .right {
    height: 160px;
  }
}

@media (max-width: 1300px) {
  .left div:nth-child(2) {
    font-size: 13px;
  }
  .leftheader img {
    width: 40%;
    margin-top: -20px;
  }
}
@media (max-width: 1250px) {
  .blueline {
    width: 5%;
  }
  .rightworks {
    width: 72%;
  }
}
@media (max-width: 1200px) {
  .blueline {
    width: 5%;
    float: left;
    border: 3px dashed #7cd6e4;
  }
}
@media (max-width: 1170px) {
  .head1 {
    width: 115px;
    height: 115px;
  }
  .head1 div:nth-child(2) {
    font-size: 12px;
    padding-top: 6px;
  }
}
@media (max-width: 1100px) {
  .left div:nth-child(2) {
    font-size: 14px;
  }
  .carousal {
    padding-top: 6%;
  }
}
@media (max-width: 1070px) {
  .works {
    width: 75% !important;
  }
}
@media (max-width: 1050px) {
  .wrapper3 {
    width: 50%;
  }
}
@media (max-width: 1003px) {
  .head1 {
    width: 110px;
    height: 110px;
  }
}
@media (max-width: 970px) {
  .works {
    width: 80% !important;
  }
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
  .btn1 {
    width: 120% !important;
    position: relative;
    left: -15% !important;
  }
  .payment {
    position: relative;
    left: -15% !important;
  }
  .num {
    position: relative !important;
    left: 0% !important;
  }
}

@media (max-width: 952px) {
  .leftheader img {
    width: 50%;
    margin-top: -20px;
  }
  .wrapper3 {
    width: 60%;
  }
  .bg {
    position: relative;
    top: -26%;
    left: 10%;
  }
  .bg img {
    width: 80%;
  }
  .wrapper {
    width: 90%;
    margin: 0% auto;
  }
  .wrapper1 {
    width: 90%;
    margin: 0% auto;
  }
  .wrapper2 {
    margin: 5% auto 10%;
  }
  .approachcontent {
    display: block;
  }
  .content-right {
    width: 100%;
    float: none;
    clear: both;
  }
  .content-right1 {
    width: 100%;
    float: none;
    clear: both;

    padding-left: 0px;
    padding-top: 20px;
    text-align: left;
  }
  .content-right1 .content-text {
    width: 100%;
  }
  .content-right .content-text {
    width: 100%;
  }
  .content-heading {
    text-align: center;
  }
  .approachcontent-left {
    width: 100% !important;
    clear: both !important;
    float: none !important;
  }
  .approachcontent-left img {
    width: 100% !important;
  }
  .wrapper3 {
    width: 90%;
  }
  .left {
    width: 100%;
    float: none;
    clear: both;
  }
  .right {
    width: 100%;
    float: none;
    clear: both;
  }

  .works {
    width: 90% !important;
    float: none;
    clear: both;
    margin-top: 15% !important;
  }
  .leftworks {
    width: 100%;
    float: none;
    clear: both;
  }

  .rightworks {
    width: 100%;
    float: none;
    clear: both;
  }
  .rightTop {
    margin-top: 20px;
  }
  .blueline {
    display: none;
  }
  .rightCenter .head1:nth-child(1) {
    margin-left: 20px;
  }
  .head1 {
    width: 150px;
    height: 150px;

    /*width: 100px;
    height: 100px;*/
    float: left;
    margin-right: 12px;
  }
  .head1 div:nth-child(2) {
    color: #000;
    padding-top: 16px;
    font-size: 15px;
    text-align: center;
  }

  .carousal {
    width: 90%;
    margin: 0% auto;
    height: 80vh;
  }

  .maincontent {
    font-size: 37px;
  }
  /*ipad*/

  .leftheader {
    width: 35% !important;
  }
}
@media (max-width: 795px) {
  .leftheader {
    width: 30% !important;
  }
  .rightheader {
    width: 65% !important;
    float: right;
  }
  .new {
    width: 80% !important;
  }
  .rightheader {
    display: none;
  }
  .newmobilerightheader {
    width: 50%;
    float: left;
    display: block;
  }
  .leftheader {
    width: 50%;
  }
  .leftheader img {
    width: 60%;
  }
  .newmobilerightheader .btnDivmobile .btn1 {
    padding: 0px;
    background: #fff;
    width: 120px;
    color: #000;
  }
  .newmobilerightheader .btnDivtext {
    padding-top: 0px;
  }
  .newmobilerightheader .btnDivcontact {
    padding-top: 0px;
    margin-right: 0px;
  }
  .newmobilerightheader1 {
    width: 35%;
    float: right;
    display: block;
  }
  .newmobilerightheader1 .btnDivmobile1 .btn11 {
    padding: 0px;
    background: #fff;
    width: 140px;
    border-radius: 10px;
    color: #000;
    font-weight: bold;
    height: 45px;
    border: 3px solid #9941e5 !important;
    font-size: 12px;
    margin-bottom: 8px;
  }
  .newmobilerightheader1 .btnDivmobile1 .btn11:hover {
    background-color: #9941e5;
    color: #fff;
  }
  .newmobilerightheader1 .btnDivtext1 {
    padding-top: 0px;
    font-size: 15px;
  }
  .newmobilerightheader1 .btnDivcontact1 {
    padding-top: 0px;
    margin-right: 0px;
  }
  .btn11 {
    /*border-radius: 10px;
    width: 90%;
    height: 50px;

    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
    border: none!important;*/
  }

  .btnDivmobile1 {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  .btnDivtext1 {
    font-weight: bold;
    font-size: 18px;
    color: #000000;

    text-align: center;
    padding-top: 13px;
  }
  .btnDivcontact1 {
    letter-spacing: 0px;
    color: #9941e5;
    opacity: 1;
    padding-bottom: 2px;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    padding-top: 10px;
    margin-right: 20px;
  }
}

@media (max-width: 770px) {
  .approachcontent1 {
    display: block;
  }
  .wrapper1 .approachcontent1 .approachcontent-left {
    width: 100%;
    float: none;
    clear: both;
  }
  .wrapper1 .approachcontent1 .content-right1 {
    width: 100%;
    float: none;
    padding-left: 0;
  }
  .wrapper1 .approachcontent1 .content-right1 .content-text {
    width: 100%;
  }
  .wrapper1 .approachcontent-left img {
    width: 100%;
  }
  .head1 {
    width: 130px;
    height: 130px;
  }
  .head1 div:nth-child(2) {
    padding-top: 14px;
    font-size: 13px;
  }
  .offset-2 {
    margin-left: 0 !important;
  }
  .quote-footer {
    text-align: center;
  }
  .btn1 {
    width: 70%;
  }

  .newipad {
    display: block;
    width: 100%;
    position: relative;
    left: -5% !important;
  }
  .newipad .btnDivmobile {
    float: left;
    width: 33%;
    text-align: center;
    margin-left: 15%;
  }
  .newipad .btnDivtext {
    float: left;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    text-align: center;
    padding-top: 13px;
    margin-right: 20px;
    width: 14%;
  }
  .newipad .btnDivcontact {
    float: left;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #9941e5;
    opacity: 1;
    padding-bottom: 2px;
    text-align: center;
    padding-top: 10px;
    margin-right: 20px;
    width: 25%;
    position: relative;
    left: -4%;
  }
  .new {
    display: none;
  }
  .afterpaynow {
    display: none;
  }
  .afternotice {
    display: none;
  }
}
@media (max-width: 670px) {
  .head1 {
    width: 120px;
    height: 120px;
  }
  .afternotice {
    margin-top: 10px !important;
  }
}
@media (max-width: 620px) {
  .head1 {
    width: 100px;
    height: 100px;
  }
  .head1 div:nth-child(2) {
    padding-top: 6px;
    font-size: 11px;
  }
}

@media (max-width: 580px) {
  .newipad {
    display: none;
  }
  .btnDivcontact {
    margin-right: 0px !important;
  }
  .carousal {
    height: 85vh;
  }
  .head1 {
    width: 80px;
    height: 80px;
    float: left;
    margin-right: 30px;
  }
  .head1 div:nth-child(2) {
    color: #000;
    padding-top: 0px;
    font-size: 8px;
    text-align: center;
  }

  .new {
    display: none;
  }
  .newmobile {
    display: block;
  }
  .btnDivmobile {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .btnDivmobile .btn1 {
    padding: 10px;
    background: #fff;
    width: 210px;
    color: #000;
    font-size: 16px;
    text-align: center;
    border: none;
    border-radius: 10px;
    letter-spacing: 3px;
    position: initial;
  }
  .btnDivtext {
    font-weight: bold;
    font-size: 18px;
    color: #000000;

    text-align: center;
    padding-top: 13px;
  }
  .btnDivcontact {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #9941e5;
    opacity: 1;
    padding-bottom: 2px;

    text-align: center;
    padding-top: 10px;
    margin-right: 20px;
  }
  .afternotice {
    display: none;
  }
  .afterpaynow {
    display: none;
  }
  .rightheader {
    display: none;
  }
  .newmobilerightheader {
    width: 50%;
    float: left;
    display: block;
  }
  .leftheader {
    width: 50%;
  }
  .leftheader img {
    width: 100%;
  }
  .newmobilerightheader .btnDivmobile .btn1 {
    padding: 0px;
    background: #fff;
    width: 120px;
    color: #000;
  }
  .newmobilerightheader .btnDivtext {
    padding-top: 0px;
  }
  .newmobilerightheader .btnDivcontact {
    padding-top: 0px;
    margin-right: 0px;
  }
  .newmobilerightheader1 {
    width: 35%;
    float: right;
    display: block;
  }
  .newmobilerightheader1 .btnDivmobile1 .btn11 {
    padding: 0px;
    background: #fff;
    width: 100px;
    border-radius: 10px;
    color: #000;
    height: 50px;
    font-weight: bold;
    height: 35px;
    border: 3px solid #9941e5 !important;
    font-size: 12px;
    margin-bottom: 8px;
  }
  .newmobilerightheader1 .btnDivmobile1 .btn11:hover {
    background-color: #9941e5 !important;
    color: #fff;
  }
  .newmobilerightheader1 .btnDivtext1 {
    padding-top: 0px;
    font-size: 12px;
  }
  .newmobilerightheader1 .btnDivcontact1 {
    padding-top: 0px;
    margin-right: 0px;
  }
  .btn11 {
    /*border-radius: 10px;
    width: 90%;
    height: 50px;

    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
    border: none!important;*/
  }

  .btnDivmobile1 {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  .btnDivtext1 {
    font-weight: bold;
    font-size: 18px;
    color: #000000;

    text-align: center;
    padding-top: 13px;
  }
  .btnDivcontact1 {
    letter-spacing: 0px;
    color: #9941e5;
    opacity: 1;
    padding-bottom: 2px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding-top: 10px;
    margin-right: 20px;
  }
}

@media (max-width: 550px) {
  .maincontent {
    font-size: 26px;
  }
}

@media (max-width: 510px) {
  .bg {
    position: relative;
    top: -20%;
    left: 3%;
  }
  .bg img {
    width: 100%;
  }
  .rightCenter {
    /*display: none;*/
  }
  .head1 {
    margin-right: 20px;
  }

  .rightCentermobile {
    width: 100%;
    display: block;
  }
  .rightcentertopmobile {
    width: 100%;
    display: block;
  }
  .head1mobile {
    width: 100%;
    float: left;
    background-color: #fff;
  }
  .head1inside {
    width: 160px;
    height: 160px;
    box-shadow: 0px 1px 10px #00000029;
    border-radius: 50%;
  }
  .head1mobile div:nth-child(1) {
    padding-top: 10px;
    color: #9941e5;
    font-weight: bold;
    text-align: center;
  }
  .head1mobile div:nth-child(2) {
    color: #000;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 8px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  .carousal {
    height: 75vh;
  }
}

@media (max-width: 470px) {
  .head1 {
    margin-right: 10px;
  }
}
@media (max-width: 425px) {
  .head1 {
    margin-right: 5px;
  }
  .rightCenter .head1:nth-child(1) {
    margin-left: 10px;
  }
}
@media (max-width: 400px) {
  .btnDivmobile1 {
    margin-top: 20px;
  }
  .leftheader img {
    margin-top: -10px;
  }
}

@media (max-width: 390px) {
  .rightCenter .head1:nth-child(1) {
    margin-left: 5px;
  }
}
@media (max-width: 385px) {
  .head1 {
    width: 75px;
    height: 75px;
  }
  .head1 div:nth-child(2) {
    font-size: 7px;
  }
}
@media (max-width: 360px) {
  .head1 {
    width: 70px;
    height: 70px;
  }
  .head1 div:nth-child(2) {
    font-size: 6px;
  }
}
@media (max-width: 340px) {
  .head1 {
    width: 65px;
    height: 65px;
  }
  .head1 div:nth-child(2) {
    font-size: 6px;
  }
}
@media (max-width: 320px) {
  .head1 {
    width: 60px;
    height: 60px;
  }
  .head1 div:nth-child(2) {
    font-size: 5px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 952px) {
  .approachcontent1 {
    width: 100% !important;
    margin: auto !important;
    display: block !important;
    align-items: center !important;
  }
  .wrapper1 .approachcontent-left {
    width: 100%;
    float: left;
    text-align: left;
  }
  .wrapper1 .approachcontent-left img {
    width: 100%;
    height: auto;
  }
  .wrapper1 .content-right1 {
    width: 100%;
    float: left;
    padding-left: 20px;
  }
  .wrapper1 .content-right1 .content-text {
    width: 100%;
    font-size: 16px;
    color: #707070;
  }
}
@media (min-width: 1800px) {
  .newipad {
    display: none;
  }
}

.phone-number {
  white-space: nowrap;
  text-decoration: none;
  color: #9941e5 !important;
}

.top-get-quote-wrap {
  max-width: 720px;
  margin: 2% auto 10%;
}

.get-quote-wrap {
  max-width: 720px;
  margin: 6% auto 6%;
}
