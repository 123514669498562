/* hold images together and maintain their scaling */
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgContainer {
  position: relative;
  width: 50%;
}

/* base car image */
.baseImage {
  position: relative;
  width: 100%;
  object-fit: cover;
  z-index: 1;
}

/* inactive layer (switching between tinted and normal) is underneath and invisible so it doesnt load when toggled */
.baseImageInactive {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0.5;
  object-fit: cover;
}

.brokenGlass,
.brokenGlassAlt {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  object-fit: cover;
}

/* used for Coupe */
.brokenGlassAlt {
  transform: translate(30px, 30px);
}

/* transparent layer for image map */
.selectionLayer {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  opacity: 0;
  object-fit: cover;
}
